import React from "react"

const Footer = () => {
  return (
  <div style={{display: 'flex', flex: '1', justifyContent: 'center', borderBottom: '3px #ddd solid'}}>
    <div style={{margin: '8px', fontSize: '50px'}}>Hillsdale Station</div>
  </div>
  )
}

export default Footer
