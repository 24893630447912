import React from "react"
import { useStaticQuery, graphql } from "gatsby"

const Footer = () => {
  const { site } = useStaticQuery(
    graphql`query {
      site {
        siteMetadata {
          author
        }
      }
    }`
  )

  return (
  <div style={{display: 'flex', flex: '1', justifyContent: 'flex-end', fontSize: '12px', margin: '8px', padding: '8px', borderTop: '3px #ddd solid'}}>
    &copy; {new Date().getFullYear()} {site.siteMetadata.author}
  </div>
  )
}

export default Footer
