import React from "react"
import "./page.scss"
import Header from "./header"
import Footer from "./footer"

const Page = ({ children, context }) => {
  return (
    <>
      <Header />
      <main>{children}</main>
      <Footer />
    </>
  )
}

export default Page
